import {Injectable} from '@angular/core';
import {AlertService} from "@ratespecial/core";

@Injectable()
export class HttpErrorService {

    constructor(private alertService: AlertService) {
    }

    /**
     * Handle any http error that comes back from Server side
     * @param err
     */
    handleError(err) {
        if(err.error.hasOwnProperty('errors')) {
            //Assume we have an array
            this.handleErrorArray(err);

        } else if(typeof err.error === 'object') {
            this.handleErrorObject(err.error);

        } else {
            //Assuming error is just a string
            this.alertService.error(err.error);
        }
    }


    /**
     * Handle an error that is an object
     * @param error
     */
    handleErrorObject(error) {
        if (this.isApiError(error)) {
            this.alertService.addApiError(error);
        } else {
            this.alertService.error(error.error);
        }
    }

    /**
     * Handle array of errors
     * @param err
     */
    handleErrorArray(err) {
        err.error.errors.map(error => {
            //Are the errors an array of API Error Objects or an array of strings
            if (typeof error === 'string') {
                this.alertService.error(error);
            } else {
                this.alertService.addApiError(error);
            }
        });
    }

    /**
     * Returns true if error is our internal Api Error Object
     * @param error
     * @returns {boolean}
     */
    isApiError(error) {
        return error.hasOwnProperty('code') && error.hasOwnProperty('detail');
    }
}
