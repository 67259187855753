import {Injectable} from '@angular/core';
//import {LocalStorage} from 'ngx-webstorage';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

/**
 * Remembers the last visited route.  Typically set on logout, or route guards
 * and used on login or after guard is satisfied to take user to where they were
 * originally trying to go.
 */
@Injectable()
export class HistoryService {

    constructor(private $localStorage: LocalStorageService,
                private $sessionStorage: SessionStorageService) {
    }

    clearLastVisitedRoute() {
        this.$localStorage.clear('lastVisitedRoute');
        this.$sessionStorage.clear('lastVisitedRoute');
    }

    getLastVisitedRoute() {
        return this.$localStorage.retrieve('lastVisitedRoute') || this.$sessionStorage.retrieve('lastVisitedRoute');
    }

    setLastVisitedRoute(route) {
        this.$localStorage.store('lastVisitedRoute', route);
        this.$sessionStorage.store('lastVisitedRoute', route);
    }

}
