import {Injectable} from '@angular/core';
import {User} from '../../models/user.model';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map, share, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * The user entity
   */
  protected user: User;

  /**
   * Track if we are fetching already to prevent repeat calls to backend
   */
  protected isFetching = false;

  /**
   * Observable for current fetch
   */
  public userObservable: Observable<User>;


  constructor(private http: HttpClient) {
  }

  /**
   * Fetch User Entity from server and cache locally
   */
  private fetch(): Observable<User> {
    if (this.isFetching === false) {
      this.isFetching = true;

      this.userObservable = this.http
        .get('/api/user')
        .pipe(
          share(),
          map(
            resp => resp as User
          ),
          tap(
            resp => {
              this.user = resp;
              this.isFetching = false;
            }
          )
        );
    }

    return this.userObservable;
  }


  /**
   * Get user from cache if available or fetch from server
   * @param force Always fetch a new object
   * @returns Observable<User>
   */
  public get(force?: boolean): Observable<User> {

    if (force === true) {
      this.user = undefined;
    }

    // if we already have retrieved the user data from the server, return it
    if (this.user) {
      return of(this.user)
        .pipe(
          map(
            resp => resp as User
          )
        );
    }

    return this.fetch();
  }

  /**
   * Return true if user has the named role
   */
  public hasRole(roleName: string): boolean {
    return this.user.roles.indexOf(roleName) > -1;
  }

  public hasAbility(abilityName: string): boolean {
    let hasAbility = false;

    if (this.user) {
      this.user.abilities.map(ability => {
        if (ability.name === abilityName || ability.name === '*') {
          hasAbility = true;
          return;
        }
      });
    }

    return hasAbility;
  }
}
