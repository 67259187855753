import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {JwtHelperService} from '@auth0/angular-jwt';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {map} from 'rxjs/operators';
import {TokenStoreService} from './token-store.service';
import {Router} from '@angular/router';
import {HistoryService} from './history.service';
import {AlertService} from "@ratespecial/core";

@Injectable()
export class AuthService {

    /**
     * JwtHelper Vendor Library
     */
    private jwtHelper: JwtHelperService;

    /**
     * ID for Auto Logout Timer
     */
    private autoLogoutTimerId;


    constructor(private http: HttpClient,
                private $localStorage: LocalStorageService,
                private $sessionStorage: SessionStorageService,
                private tokenService: TokenStoreService,
                private router: Router,
                private alertService: AlertService,
                private historyService: HistoryService
    ) {
        this.jwtHelper = new JwtHelperService();
    }

    /**
     * Return true if JWT has expired
     * @returns {boolean}
     */
    isTokenExpired() {
        return this.jwtHelper.isTokenExpired(this.tokenService.getToken());
    }

    /**
     * Return true is user is currently authenticated
     * @returns {boolean}
     */
    isAuthenticated(): boolean {
        let token = this.tokenService.getToken();
        return (token && token.length > 0 && this.isTokenExpired() === false) ? true : false;
    }


    /**
     * Logout - Clear auth tokens, save last route and kick user back to login page
     * @param {boolean} showExpirationError
     */
    logout(showExpirationError=false) {
        //this.historyService.setLastVisitedRoute(this.router.url);
        this.tokenService.clearAuthToken();
        this.router.navigateByUrl('/auth/login');
        if(showExpirationError) {
            this.alertService.error('Your session has expired.  Please log in.');
        }
        clearTimeout(this.autoLogoutTimerId);
    }


    /**
     * To be called after the user returns from google's oauth page.  Google will
     * pass us a code, that we must validate server side.
     * @param code
     * @returns {Observable<any>}
     */
    authenticateGoogleCode(code) {
        const postData = {code: code};

        return this.http.post('/api/auth/google', postData).pipe(map(authenticateSuccess.bind(this)));

        function authenticateSuccess(resp) {
            let rememberMe = false;
            this.tokenService.storeAuthenticationToken(resp['token'], false);
            this.setExpirationTimer(5);
        }
    }


    /**
     * Local Login
     * @param loginRequest
     * @returns {Observable<void>}
     */
    localLogin(loginRequest) {
        return this.http.post('/api/auth/local', loginRequest).pipe(map(
            resp => {
                this.tokenService.storeAuthenticationToken(resp['token'], false);
                this.setExpirationTimer(5);
            }
        ));
    }


    /**
     * Starts time to auto logout user based on JWT expiration time
     * @param {number} offsetSeconds
     */
    setExpirationTimer(offsetSeconds: number=0) {

        let expirationDate = this.jwtHelper.getTokenExpirationDate(this.tokenService.getToken());

        if (expirationDate === null) {
            let timeout = (expirationDate.valueOf() - (offsetSeconds * 1000)) - new Date().valueOf();

            this.autoLogoutTimerId = setTimeout(() => {
                this.logout();
            }, timeout);
        }
    }

}
