import {Injectable} from '@angular/core';
//import {LocalStorage} from 'ngx-webstorage';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

/**
 * This class exists to hold an authentication token that was acquired through
 * AuthService.  This is to separate the acquisition of a token from the usage,
 * which helps avoid cyclic dependencies.
 */
@Injectable()
export class TokenStoreService {

//  @LocalStorage() public token: string;

  constructor(
      private $localStorage: LocalStorageService,
      private $sessionStorage: SessionStorageService
  ) {}


  clearAuthToken()
  {
    this.$localStorage.clear('authenticationToken');
    this.$sessionStorage.clear('authenticationToken');
  }

  getToken() {
    return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
  }

  storeAuthenticationToken(jwt, rememberMe) {
    if (rememberMe) {
      this.$localStorage.store('authenticationToken', jwt);
    } else {
      this.$sessionStorage.store('authenticationToken', jwt);
    }
  }
}
